var render = function render(){
  var _vm$formData$context, _vm$$refs$setting;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "emoji-edit"
  }, [_c('emoji-box', {
    on: {
      "select": _vm.selectEmoji
    }
  })], 1), _c('div', {
    staticClass: "main-row"
  }, [_c('div', {
    staticClass: "view-card"
  }, [_c('el-image', {
    staticClass: "plat-logo",
    attrs: {
      "fit": "cover",
      "src": _vm.$platformInfo.logo
    }
  }, [_c('i', {
    staticClass: "el-icon-picture",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  })]), _c('div', {
    staticClass: "message-box"
  }, [_vm._v(" " + _vm._s(_vm.formData.context || "请输入文字消息") + " "), _c('span', {
    staticClass: "t-right-n"
  })])], 1), _c('div', {
    staticClass: "editor-container"
  }, [_c('div', {
    staticClass: "edit-main"
  }, [_c('el-input', {
    attrs: {
      "id": "textInput",
      "type": "textarea",
      "resize": "none",
      "size": "mini",
      "placeholder": "在这里开始输入文字消息"
    },
    model: {
      value: _vm.formData.context,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "context", $$v);
      },
      expression: "formData.context"
    }
  }), _c('public-setting', {
    ref: "setting",
    staticClass: "edit-set",
    attrs: {
      "detailForm": _vm.detailForm
    }
  }), _c('c-foot-bar', {
    attrs: {
      "word-count": (_vm$formData$context = _vm.formData.context) === null || _vm$formData$context === void 0 ? void 0 : _vm$formData$context.length,
      "form-data": Object.assign({}, _vm.formData, (_vm$$refs$setting = _vm.$refs.setting) === null || _vm$$refs$setting === void 0 ? void 0 : _vm$$refs$setting.formData),
      "before-submit": _vm.validateForm
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }