// 实现灵感来自quill-emoji；通过js获取该组件渲染的表情字符存为对象 再通过正则替换掉它的class
// JSON.stringify(Array.from(document.querySelectorAll('#tab-panel span')).map(item => ({key: item.className, char: item.innerText})))
export default {
  people: [
    { key: "fem-grinning", char: "😀" },
    { key: "fem-speech", char: "💬" },
    { key: "fem-grin", char: "😁" },
    { key: "fem-joy", char: "😂" },
    { key: "fem-smiley", char: "😃" },
    { key: "fem-smile", char: "😄" },
    { key: "fem-sweat_smile", char: "😅" },
    { key: "fem-laughing", char: "😆" },
    { key: "fem-wink", char: "😉" },
    { key: "fem-blush", char: "😊" },
    { key: "fem-yum", char: "😋" },
    { key: "fem-sunglasses", char: "😎" },
    { key: "fem-heart_eyes", char: "😍" },
    { key: "fem-kissing_heart", char: "😘" },
    { key: "fem-kissing", char: "😗" },
    {
      key: "fem-kissing_smiling_eyes",
      char: "😙"
    },
    {
      key: "fem-kissing_closed_eyes",
      char: "😚"
    },
    {
      key: "fem-slightly_smiling_face",
      char: "🙂"
    },
    { key: "fem-hugging_face", char: "🤗" },
    { key: "fem-thinking_face", char: "🤔" },
    { key: "fem-neutral_face", char: "😐" },
    { key: "fem-expressionless", char: "😑" },
    { key: "fem-no_mouth", char: "😶" },
    {
      key: "fem-face_with_rolling_eyes",
      char: "🙄"
    },
    { key: "fem-smirk", char: "😏" },
    { key: "fem-persevere", char: "😣" },
    {
      key: "fem-disappointed_relieved",
      char: "😥"
    },
    { key: "fem-open_mouth", char: "😮" },
    { key: "fem-zipper_mouth_face", char: "🤐" },
    { key: "fem-hushed", char: "😯" },
    { key: "fem-sleepy", char: "😪" },
    { key: "fem-tired_face", char: "😫" },
    { key: "fem-sleeping", char: "😴" },
    { key: "fem-relieved", char: "😌" },
    { key: "fem-nerd_face", char: "🤓" },
    { key: "fem-stuck_out_tongue", char: "😛" },
    {
      key: "fem-stuck_out_tongue_winking_eye",
      char: "😜"
    },
    {
      key: "fem-stuck_out_tongue_closed_eyes",
      char: "😝"
    },
    { key: "fem-unamused", char: "😒" },
    { key: "fem-sweat", char: "😓" },
    { key: "fem-pensive", char: "😔" },
    { key: "fem-confused", char: "😕" },
    { key: "fem-upside_down_face", char: "🙃" },
    { key: "fem-money_mouth_face", char: "🤑" },
    { key: "fem-astonished", char: "😲" },
    {
      key: "fem-slightly_frowning_face",
      char: "🙁"
    },
    { key: "fem-confounded", char: "😖" },
    { key: "fem-disappointed", char: "😞" },
    { key: "fem-worried", char: "😟" },
    { key: "fem-triumph", char: "😤" },
    { key: "fem-cry", char: "😢" },
    { key: "fem-sob", char: "😭" },
    { key: "fem-frowning", char: "😦" },
    { key: "fem-anguished", char: "😧" },
    { key: "fem-fearful", char: "😨" },
    { key: "fem-weary", char: "😩" },
    { key: "fem-grimacing", char: "😬" },
    { key: "fem-cold_sweat", char: "😰" },
    { key: "fem-scream", char: "😱" },
    { key: "fem-flushed", char: "😳" },
    { key: "fem-dizzy_face", char: "😵" },
    { key: "fem-rage", char: "😡" },
    { key: "fem-angry", char: "😠" },
    { key: "fem-innocent", char: "😇" },
    { key: "fem-mask", char: "😷" },
    {
      key: "fem-face_with_thermometer",
      char: "🤒"
    },
    {
      key: "fem-face_with_head_bandage",
      char: "🤕"
    },
    { key: "fem-smiling_imp", char: "😈" },
    { key: "fem-imp", char: "👿" },
    { key: "fem-japanese_ogre", char: "👹" },
    { key: "fem-japanese_goblin", char: "👺" },
    { key: "fem-skull", char: "💀" },
    { key: "fem-ghost", char: "👻" },
    { key: "fem-alien", char: "👽" },
    { key: "fem-robot_face", char: "🤖" },
    { key: "fem-hankey", char: "💩" },
    { key: "fem-smiley_cat", char: "😺" },
    { key: "fem-smile_cat", char: "😸" },
    { key: "fem-joy_cat", char: "😹" },
    { key: "fem-heart_eyes_cat", char: "😻" },
    { key: "fem-smirk_cat", char: "😼" },
    { key: "fem-kissing_cat", char: "😽" },
    { key: "fem-scream_cat", char: "🙀" },
    { key: "fem-crying_cat_face", char: "😿" },
    { key: "fem-pouting_cat", char: "😾" },
    { key: "fem-boy", char: "👦" },
    { key: "fem-girl", char: "👧" },
    { key: "fem-man", char: "👨" },
    { key: "fem-woman", char: "👩" },
    { key: "fem-older_man", char: "👴" },
    { key: "fem-older_woman", char: "👵" },
    { key: "fem-baby", char: "👶" },
    { key: "fem-angel", char: "👼" },
    { key: "fem-cop", char: "👮" },
    { key: "fem-sleuth_or_spy", char: "🕵" },
    { key: "fem-guardsman", char: "💂" },
    {
      key: "fem-construction_worker",
      char: "👷"
    },
    { key: "fem-man_with_turban", char: "👳" },
    {
      key: "fem-person_with_blond_hair",
      char: "👱"
    },
    { key: "fem-santa", char: "🎅" },
    { key: "fem-princess", char: "👸" },
    { key: "fem-bride_with_veil", char: "👰" },
    {
      key: "fem-man_with_gua_pi_mao",
      char: "👲"
    },
    { key: "fem-person_frowning", char: "🙍" },
    {
      key: "fem-person_with_pouting_face",
      char: "🙎"
    },
    { key: "fem-no_good", char: "🙅" },
    { key: "fem-ok_woman", char: "🙆" },
    {
      key: "fem-information_desk_person",
      char: "💁"
    },
    { key: "fem-raising_hand", char: "🙋" },
    { key: "fem-bow", char: "🙇" },
    { key: "fem-massage", char: "💆" },
    { key: "fem-haircut", char: "💇" },
    { key: "fem-walking", char: "🚶" },
    { key: "fem-runner", char: "🏃" },
    { key: "fem-dancer", char: "💃" },
    { key: "fem-dancers", char: "👯" },
    {
      key: "fem-speaking_head_in_silhouette",
      char: "🗣"
    },
    { key: "fem-bust_in_silhouette", char: "👤" },
    {
      key: "fem-busts_in_silhouette",
      char: "👥"
    },
    { key: "fem-couple", char: "👫" },
    {
      key: "fem-two_men_holding_hands",
      char: "👬"
    },
    {
      key: "fem-two_women_holding_hands",
      char: "👭"
    },
    { key: "fem-couplekiss", char: "💏" },
    { key: "fem-couple_with_heart", char: "💑" },
    { key: "fem-family", char: "👪" },
    { key: "fem-muscle", char: "💪" },
    { key: "fem-point_left", char: "👈" },
    { key: "fem-point_right", char: "👉" },
    { key: "fem-point_up", char: "☝" },
    { key: "fem-point_up_2", char: "👆" },
    { key: "fem-middle_finger", char: "🖕" },
    { key: "fem-point_down", char: "👇" },
    { key: "fem-v", char: "✌" },
    { key: "fem-the_horns", char: "🤘" },
    {
      key: "fem-raised_hand_with_fingers_splayed",
      char: "🖐"
    },
    { key: "fem-ok_hand", char: "👌" },
    { key: "fem-thumbsup", char: "👍" },
    { key: "fem-thumbsdown", char: "👎" },
    { key: "fem-fist", char: "✊" },
    { key: "fem-facepunch", char: "👊" },
    { key: "fem-wave", char: "👋" },
    { key: "fem-clap", char: "👏" },
    { key: "fem-writing_hand", char: "✍" },
    { key: "fem-open_hands", char: "👐" },
    { key: "fem-raised_hands", char: "🙌" },
    { key: "fem-pray", char: "🙏" },
    { key: "fem-nail_care", char: "💅" },
    { key: "fem-ear", char: "👂" },
    { key: "fem-nose", char: "👃" },
    { key: "fem-footprints", char: "👣" },
    { key: "fem-eyes", char: "👀" },
    { key: "fem-eye", char: "👁" },
    { key: "fem-tongue", char: "👅" },
    { key: "fem-lips", char: "👄" },
    { key: "fem-kiss", char: "💋" },
    { key: "fem-zzz", char: "💤" },
    { key: "fem-eyeglasses", char: "👓" },
    { key: "fem-dark_sunglasses", char: "🕶" },
    { key: "fem-necktie", char: "👔" },
    { key: "fem-shirt", char: "👕" },
    { key: "fem-jeans", char: "👖" },
    { key: "fem-dress", char: "👗" },
    { key: "fem-kimono", char: "👘" },
    { key: "fem-bikini", char: "👙" },
    { key: "fem-womans_clothes", char: "👚" },
    { key: "fem-purse", char: "👛" },
    { key: "fem-handbag", char: "👜" },
    { key: "fem-pouch", char: "👝" },
    { key: "fem-school_satchel", char: "🎒" },
    { key: "fem-mans_shoe", char: "👞" },
    { key: "fem-athletic_shoe", char: "👟" },
    { key: "fem-high_heel", char: "👠" },
    { key: "fem-sandal", char: "👡" },
    { key: "fem-boot", char: "👢" },
    { key: "fem-crown", char: "👑" },
    { key: "fem-womans_hat", char: "👒" },
    { key: "fem-tophat", char: "🎩" },
    { key: "fem-mortar_board", char: "🎓" },
    {
      key: "fem-helmet_with_white_cross",
      char: "⛑"
    },
    { key: "fem-lipstick", char: "💄" },
    { key: "fem-ring", char: "💍" },
    { key: "fem-closed_umbrella", char: "🌂" },
    { key: "fem-briefcase", char: "💼" }
  ],
  nature: [
    { key: "fem-see_no_evil", char: "🙈" },
    { key: "fem-hear_no_evil", char: "🙉" },
    { key: "fem-speak_no_evil", char: "🙊" },
    { key: "fem-sweat_drops", char: "💦" },
    { key: "fem-dash", char: "💨" },
    { key: "fem-monkey_face", char: "🐵" },
    { key: "fem-monkey", char: "🐒" },
    { key: "fem-dog", char: "🐶" },
    { key: "fem-dog2", char: "🐕" },
    { key: "fem-poodle", char: "🐩" },
    { key: "fem-wolf", char: "🐺" },
    { key: "fem-cat", char: "🐱" },
    { key: "fem-cat2", char: "🐈" },
    { key: "fem-lion_face", char: "🦁" },
    { key: "fem-tiger", char: "🐯" },
    { key: "fem-tiger2", char: "🐅" },
    { key: "fem-leopard", char: "🐆" },
    { key: "fem-horse", char: "🐴" },
    { key: "fem-racehorse", char: "🐎" },
    { key: "fem-unicorn_face", char: "🦄" },
    { key: "fem-cow", char: "🐮" },
    { key: "fem-ox", char: "🐂" },
    { key: "fem-water_buffalo", char: "🐃" },
    { key: "fem-cow2", char: "🐄" },
    { key: "fem-pig", char: "🐷" },
    { key: "fem-pig2", char: "🐖" },
    { key: "fem-boar", char: "🐗" },
    { key: "fem-pig_nose", char: "🐽" },
    { key: "fem-ram", char: "🐏" },
    { key: "fem-sheep", char: "🐑" },
    { key: "fem-goat", char: "🐐" },
    { key: "fem-dromedary_camel", char: "🐪" },
    { key: "fem-camel", char: "🐫" },
    { key: "fem-elephant", char: "🐘" },
    { key: "fem-mouse", char: "🐭" },
    { key: "fem-mouse2", char: "🐁" },
    { key: "fem-rat", char: "🐀" },
    { key: "fem-hamster", char: "🐹" },
    { key: "fem-rabbit", char: "🐰" },
    { key: "fem-rabbit2", char: "🐇" },
    { key: "fem-chipmunk", char: "🐿" },
    { key: "fem-bear", char: "🐻" },
    { key: "fem-koala", char: "🐨" },
    { key: "fem-panda_face", char: "🐼" },
    { key: "fem-feet", char: "🐾" },
    { key: "fem-turkey", char: "🦃" },
    { key: "fem-chicken", char: "🐔" },
    { key: "fem-rooster", char: "🐓" },
    { key: "fem-hatching_chick", char: "🐣" },
    { key: "fem-baby_chick", char: "🐤" },
    { key: "fem-hatched_chick", char: "🐥" },
    { key: "fem-bird", char: "🐦" },
    { key: "fem-penguin", char: "🐧" },
    { key: "fem-dove_of_peace", char: "🕊" },
    { key: "fem-frog", char: "🐸" },
    { key: "fem-crocodile", char: "🐊" },
    { key: "fem-turtle", char: "🐢" },
    { key: "fem-snake", char: "🐍" },
    { key: "fem-dragon_face", char: "🐲" },
    { key: "fem-dragon", char: "🐉" },
    { key: "fem-whale", char: "🐳" },
    { key: "fem-whale2", char: "🐋" },
    { key: "fem-dolphin", char: "🐬" },
    { key: "fem-fish", char: "🐟" },
    { key: "fem-tropical_fish", char: "🐠" },
    { key: "fem-blowfish", char: "🐡" },
    { key: "fem-octopus", char: "🐙" },
    { key: "fem-shell", char: "🐚" },
    { key: "fem-crab", char: "🦀" },
    { key: "fem-snail", char: "🐌" },
    { key: "fem-bug", char: "🐛" },
    { key: "fem-ant", char: "🐜" },
    { key: "fem-bee", char: "🐝" },
    { key: "fem-beetle", char: "🐞" },
    { key: "fem-spider", char: "🕷" },
    { key: "fem-spider_web", char: "🕸" },
    { key: "fem-scorpion", char: "🦂" },
    { key: "fem-bouquet", char: "💐" },
    { key: "fem-cherry_blossom", char: "🌸" },
    { key: "fem-rosette", char: "🏵" },
    { key: "fem-rose", char: "🌹" },
    { key: "fem-hibiscus", char: "🌺" },
    { key: "fem-sunflower", char: "🌻" },
    { key: "fem-blossom", char: "🌼" },
    { key: "fem-tulip", char: "🌷" },
    { key: "fem-seedling", char: "🌱" },
    { key: "fem-evergreen_tree", char: "🌲" },
    { key: "fem-deciduous_tree", char: "🌳" },
    { key: "fem-palm_tree", char: "🌴" },
    { key: "fem-cactus", char: "🌵" },
    { key: "fem-ear_of_rice", char: "🌾" },
    { key: "fem-herb", char: "🌿" },
    { key: "fem-shamrock", char: "☘" },
    { key: "fem-four_leaf_clover", char: "🍀" },
    { key: "fem-maple_leaf", char: "🍁" },
    { key: "fem-fallen_leaf", char: "🍂" },
    { key: "fem-leaves", char: "🍃" },
    { key: "fem-mushroom", char: "🍄" },
    { key: "fem-chestnut", char: "🌰" },
    { key: "fem-earth_africa", char: "🌍" },
    { key: "fem-earth_americas", char: "🌎" },
    { key: "fem-earth_asia", char: "🌏" },
    { key: "fem-new_moon", char: "🌑" },
    {
      key: "fem-waxing_crescent_moon",
      char: "🌒"
    },
    { key: "fem-first_quarter_moon", char: "🌓" },
    { key: "fem-full_moon", char: "🌕" },
    {
      key: "fem-waning_gibbous_moon",
      char: "🌖"
    },
    { key: "fem-last_quarter_moon", char: "🌗" },
    {
      key: "fem-waning_crescent_moon",
      char: "🌘"
    },
    { key: "fem-crescent_moon", char: "🌙" },
    { key: "fem-new_moon_with_face", char: "🌚" },
    {
      key: "fem-first_quarter_moon_with_face",
      char: "🌛"
    },
    {
      key: "fem-last_quarter_moon_with_face",
      char: "🌜"
    },
    { key: "fem-sunny", char: "☀" },
    {
      key: "fem-full_moon_with_face",
      char: "🌝"
    },
    { key: "fem-sun_with_face", char: "🌞" },
    { key: "fem-star", char: "⭐" },
    { key: "fem-star2", char: "🌟" },
    { key: "fem-cloud", char: "☁" },
    { key: "fem-partly_sunny", char: "⛅" },
    {
      key: "fem-thunder_cloud_and_rain",
      char: "⛈"
    },
    { key: "fem-rain_cloud", char: "🌧" },
    { key: "fem-snow_cloud", char: "🌨" },
    { key: "fem-fog", char: "🌫" },
    { key: "fem-wind_blowing_face", char: "🌬" },
    { key: "fem-umbrella", char: "☂" },
    {
      key: "fem-umbrella_with_rain_drops",
      char: "☔"
    },
    { key: "fem-zap", char: "⚡" },
    { key: "fem-snowflake", char: "❄" },
    { key: "fem-snowman", char: "☃" },
    {
      key: "fem-snowman_without_snow",
      char: "⛄"
    },
    { key: "fem-comet", char: "☄" },
    { key: "fem-fire", char: "🔥" },
    { key: "fem-droplet", char: "💧" },
    { key: "fem-ocean", char: "🌊" },
    { key: "fem-jack_o_lantern", char: "🎃" },
    { key: "fem-christmas_tree", char: "🎄" },
    { key: "fem-sparkles", char: "✨" },
    { key: "fem-tanabata_tree", char: "🎋" },
    { key: "fem-bamboo", char: "🎍" }
  ],
  food: [
    { key: "fem-grapes", char: "🍇" },
    { key: "fem-melon", char: "🍈" },
    { key: "fem-watermelon", char: "🍉" },
    { key: "fem-tangerine", char: "🍊" },
    { key: "fem-lemon", char: "🍋" },
    { key: "fem-banana", char: "🍌" },
    { key: "fem-pineapple", char: "🍍" },
    { key: "fem-apple", char: "🍎" },
    { key: "fem-green_apple", char: "🍏" },
    { key: "fem-pear", char: "🍐" },
    { key: "fem-peach", char: "🍑" },
    { key: "fem-cherries", char: "🍒" },
    { key: "fem-strawberry", char: "🍓" },
    { key: "fem-tomato", char: "🍅" },
    { key: "fem-eggplant", char: "🍆" },
    { key: "fem-corn", char: "🌽" },
    { key: "fem-hot_pepper", char: "🌶" },
    { key: "fem-bread", char: "🍞" },
    { key: "fem-cheese_wedge", char: "🧀" },
    { key: "fem-meat_on_bone", char: "🍖" },
    { key: "fem-poultry_leg", char: "🍗" },
    { key: "fem-hamburger", char: "🍔" },
    { key: "fem-fries", char: "🍟" },
    { key: "fem-pizza", char: "🍕" },
    { key: "fem-hotdog", char: "🌭" },
    { key: "fem-taco", char: "🌮" },
    { key: "fem-burrito", char: "🌯" },
    { key: "fem-egg", char: "🥚" },
    { key: "fem-stew", char: "🍲" },
    { key: "fem-popcorn", char: "🍿" },
    { key: "fem-bento", char: "🍱" },
    { key: "fem-rice_cracker", char: "🍘" },
    { key: "fem-rice_ball", char: "🍙" },
    { key: "fem-rice", char: "🍚" },
    { key: "fem-curry", char: "🍛" },
    { key: "fem-ramen", char: "🍜" },
    { key: "fem-spaghetti", char: "🍝" },
    { key: "fem-sweet_potato", char: "🍠" },
    { key: "fem-oden", char: "🍢" },
    { key: "fem-sushi", char: "🍣" },
    { key: "fem-fried_shrimp", char: "🍤" },
    { key: "fem-fish_cake", char: "🍥" },
    { key: "fem-dango", char: "🍡" },
    { key: "fem-icecream", char: "🍦" },
    { key: "fem-shaved_ice", char: "🍧" },
    { key: "fem-ice_cream", char: "🍨" },
    { key: "fem-doughnut", char: "🍩" },
    { key: "fem-cookie", char: "🍪" },
    { key: "fem-birthday", char: "🎂" },
    { key: "fem-cake", char: "🍰" },
    { key: "fem-chocolate_bar", char: "🍫" },
    { key: "fem-candy", char: "🍬" },
    { key: "fem-lollipop", char: "🍭" },
    { key: "fem-custard", char: "🍮" },
    { key: "fem-honey_pot", char: "🍯" },
    { key: "fem-baby_bottle", char: "🍼" },
    { key: "fem-coffee", char: "☕" },
    { key: "fem-tea", char: "🍵" },
    { key: "fem-sake", char: "🍶" },
    { key: "fem-champagne", char: "🍾" },
    { key: "fem-wine_glass", char: "🍷" },
    { key: "fem-cocktail", char: "🍸" },
    { key: "fem-tropical_drink", char: "🍹" },
    { key: "fem-beer", char: "🍺" },
    { key: "fem-beers", char: "🍻" },
    { key: "fem-knife_fork_plate", char: "🍽" },
    { key: "fem-fork_and_knife", char: "🍴" }
  ],
  symbols: [
    { key: "fem-cupid", char: "💘" },
    { key: "fem-heart", char: "❤" },
    { key: "fem-heartbeat", char: "💓" },
    { key: "fem-broken_heart", char: "💔" },
    { key: "fem-two_hearts", char: "💕" },
    { key: "fem-sparkling_heart", char: "💖" },
    { key: "fem-heartpulse", char: "💗" },
    { key: "fem-blue_heart", char: "💙" },
    { key: "fem-green_heart", char: "💚" },
    { key: "fem-yellow_heart", char: "💛" },
    { key: "fem-purple_heart", char: "💜" },
    { key: "fem-gift_heart", char: "💝" },
    { key: "fem-revolving_hearts", char: "💞" },
    { key: "fem-heart_decoration", char: "💟" },
    { key: "fem-heart_exclamation", char: "❣" },
    { key: "fem-anger", char: "💢" },
    { key: "fem-boom", char: "💥" },
    { key: "fem-dizzy", char: "💫" },
    { key: "fem-speech_balloon", char: "💬" },
    { key: "fem-left_speech_bubble", char: "🗨" },
    { key: "fem-right_anger_bubble", char: "🗯" },
    { key: "fem-thought_balloon", char: "💭" },
    { key: "fem-white_flower", char: "💮" },
    {
      key: "fem-globe_with_meridians",
      char: "🌐"
    },
    { key: "fem-hotsprings", char: "♨" },
    { key: "fem-octagonal_sign", char: "🛑" },
    { key: "fem-clock12", char: "🕛" },
    { key: "fem-clock1230", char: "🕧" },
    { key: "fem-clock1", char: "🕐" },
    { key: "fem-clock130", char: "🕜" },
    { key: "fem-clock2", char: "🕑" },
    { key: "fem-clock230", char: "🕝" },
    { key: "fem-clock3", char: "🕒" },
    { key: "fem-clock330", char: "🕞" },
    { key: "fem-clock4", char: "🕓" },
    { key: "fem-clock430", char: "🕟" },
    { key: "fem-clock5", char: "🕔" },
    { key: "fem-clock530", char: "🕠" },
    { key: "fem-clock6", char: "🕕" },
    { key: "fem-clock630", char: "🕡" },
    { key: "fem-clock7", char: "🕖" },
    { key: "fem-clock730", char: "🕢" },
    { key: "fem-clock8", char: "🕗" },
    { key: "fem-clock830", char: "🕣" },
    { key: "fem-clock9", char: "🕘" },
    { key: "fem-clock930", char: "🕤" },
    { key: "fem-clock10", char: "🕙" },
    { key: "fem-clock1030", char: "🕥" },
    { key: "fem-clock11", char: "🕚" },
    { key: "fem-clock1130", char: "🕦" },
    { key: "fem-cyclone", char: "🌀" },
    { key: "fem-spades", char: "♠" },
    { key: "fem-hearts", char: "♥" },
    { key: "fem-diamonds", char: "♦" },
    { key: "fem-clubs", char: "♣" },
    { key: "fem-black_joker", char: "🃏" },
    { key: "fem-mahjong", char: "🀄" },
    {
      key: "fem-flower_playing_cards",
      char: "🎴"
    },
    { key: "fem-mute", char: "🔇" },
    { key: "fem-speaker", char: "🔈" },
    { key: "fem-sound", char: "🔉" },
    { key: "fem-loud_sound", char: "🔊" },
    { key: "fem-loudspeaker", char: "📢" },
    { key: "fem-mega", char: "📣" },
    { key: "fem-bell", char: "🔔" },
    { key: "fem-no_bell", char: "🔕" },
    { key: "fem-musical_note", char: "🎵" },
    { key: "fem-notes", char: "🎶" },
    { key: "fem-chart", char: "💹" },
    { key: "fem-currency_exchange", char: "💱" },
    { key: "fem-heavy_dollar_sign", char: "💲" },
    { key: "fem-atm", char: "🏧" },
    {
      key: "fem-put_litter_in_its_place",
      char: "🚮"
    },
    { key: "fem-potable_water", char: "🚰" },
    { key: "fem-wheelchair", char: "♿" },
    { key: "fem-mens", char: "🚹" },
    { key: "fem-womens", char: "🚺" },
    { key: "fem-restroom", char: "🚻" },
    { key: "fem-baby_symbol", char: "🚼" },
    { key: "fem-wc", char: "🚾" },
    { key: "fem-passport_control", char: "🛂" },
    { key: "fem-customs", char: "🛃" },
    { key: "fem-baggage_claim", char: "🛄" },
    { key: "fem-left_luggage", char: "🛅" },
    { key: "fem-warning", char: "⚠" },
    { key: "fem-children_crossing", char: "🚸" },
    { key: "fem-no_entry", char: "⛔" },
    { key: "fem-no_entry_sign", char: "🚫" },
    { key: "fem-no_bicycles", char: "🚳" },
    { key: "fem-no_smoking", char: "🚭" },
    { key: "fem-do_not_litter", char: "🚯" },
    { key: "bem bem-non-potable_water ap ap-non-potable_water", char: "🚱" },
    { key: "fem-no_pedestrians", char: "🚷" },
    { key: "fem-no_mobile_phones", char: "📵" },
    { key: "fem-underage", char: "🔞" },
    { key: "fem-radioactive", char: "☢" },
    { key: "fem-biohazard", char: "☣" },
    { key: "fem-arrow_up", char: "⬆" },
    { key: "fem-arrow_upper_right", char: "↗" },
    { key: "fem-arrow_right", char: "➡" },
    { key: "fem-arrow_lower_right", char: "↘" },
    { key: "fem-arrow_down", char: "⬇" },
    { key: "fem-arrow_lower_left", char: "↙" },
    { key: "fem-arrow_left", char: "⬅" },
    { key: "fem-arrow_upper_left", char: "↖" },
    { key: "fem-arrow_up_down", char: "↕" },
    { key: "fem-left_right_arrow", char: "↔" },
    {
      key: "fem-leftwards_arrow_with_hook",
      char: "↩"
    },
    { key: "fem-arrow_right_hook", char: "↪" },
    { key: "fem-arrow_heading_up", char: "⤴" },
    { key: "fem-arrow_heading_down", char: "⤵" },
    { key: "fem-arrows_clockwise", char: "🔃" },
    {
      key: "fem-arrows_counterclockwise",
      char: "🔄"
    },
    { key: "fem-back", char: "🔙" },
    { key: "fem-end", char: "🔚" },
    { key: "fem-on", char: "🔛" },
    { key: "fem-soon", char: "🔜" },
    { key: "fem-top", char: "🔝" },
    { key: "fem-place_of_worship", char: "🛐" },
    { key: "fem-atom_symbol", char: "⚛" },
    { key: "fem-om_symbol", char: "🕉" },
    { key: "fem-star_of_david", char: "✡" },
    { key: "fem-wheel_of_dharma", char: "☸" },
    { key: "fem-yin_yang", char: "☯" },
    { key: "fem-latin_cross", char: "✝" },
    { key: "fem-orthodox_cross", char: "☦" },
    { key: "fem-star_and_crescent", char: "☪" },
    { key: "fem-peace_symbol", char: "☮" },
    {
      key: "fem-menorah_with_nine_branches",
      char: "🕎"
    },
    { key: "fem-six_pointed_star", char: "🔯" },
    { key: "fem-aries", char: "♈" },
    { key: "fem-taurus", char: "♉" },
    { key: "fem-gemini", char: "♊" },
    { key: "fem-cancer", char: "♋" },
    { key: "fem-leo", char: "♌" },
    { key: "fem-virgo", char: "♍" },
    { key: "fem-libra", char: "♎" },
    { key: "fem-scorpius", char: "♏" },
    { key: "fem-sagittarius", char: "♐" },
    { key: "fem-capricorn", char: "♑" },
    { key: "fem-aquarius", char: "♒" },
    { key: "fem-pisces", char: "♓" },
    { key: "fem-ophiuchus", char: "⛎" },
    {
      key: "fem-twisted_rightwards_arrows",
      char: "🔀"
    },
    { key: "fem-repeat", char: "🔁" },
    { key: "fem-repeat_one", char: "🔂" },
    { key: "fem-arrow_forward", char: "▶" },
    { key: "fem-fast_forward", char: "⏩" },
    {
      key: "fem-black_right_pointing_double_triangle_with_vertical_bar",
      char: "⏭"
    },
    {
      key: "fem-black_right_pointing_triangle_with_double_vertical_bar",
      char: "⏯"
    },
    { key: "fem-arrow_backward", char: "◀" },
    { key: "fem-rewind", char: "⏪" },
    {
      key: "fem-black_left_pointing_double_triangle_with_vertical_bar",
      char: "⏮"
    },
    { key: "fem-arrow_up_small", char: "🔼" },
    { key: "fem-arrow_double_up", char: "⏫" },
    { key: "fem-arrow_down_small", char: "🔽" },
    { key: "fem-arrow_double_down", char: "⏬" },
    { key: "fem-double_vertical_bar", char: "⏸" },
    {
      key: "fem-black_square_for_stop",
      char: "⏹"
    },
    {
      key: "fem-black_circle_for_record",
      char: "⏺"
    },
    { key: "fem-cinema", char: "🎦" },
    { key: "fem-low_brightness", char: "🔅" },
    { key: "fem-high_brightness", char: "🔆" },
    { key: "fem-signal_strength", char: "📶" },
    { key: "fem-vibration_mode", char: "📳" },
    { key: "fem-mobile_phone_off", char: "📴" },
    { key: "fem-recycle", char: "♻" },
    { key: "fem-name_badge", char: "📛" },
    { key: "fem-fleur_de_lis", char: "⚜" },
    { key: "fem-beginner", char: "🔰" },
    { key: "fem-trident", char: "🔱" },
    { key: "fem-o", char: "⭕" },
    { key: "fem-white_check_mark", char: "✅" },
    {
      key: "fem-ballot_box_with_check",
      char: "☑"
    },
    { key: "fem-heavy_check_mark", char: "✔" },
    {
      key: "fem-heavy_multiplication_x",
      char: "✖"
    },
    { key: "fem-x", char: "❌" },
    {
      key: "fem-negative_squared_cross_mark",
      char: "❎"
    },
    { key: "fem-heavy_plus_sign", char: "➕" },
    { key: "fem-heavy_minus_sign", char: "➖" },
    {
      key: "fem-heavy_division_sign",
      char: "➗"
    },
    { key: "fem-curly_loop", char: "➰" },
    { key: "fem-loop", char: "➿" },
    {
      key: "fem-part_alternation_mark",
      char: "〽"
    },
    {
      key: "fem-eight_spoked_asterisk",
      char: "✳"
    },
    {
      key: "fem-eight_pointed_black_star",
      char: "✴"
    },
    { key: "fem-sparkle", char: "❇" },
    { key: "fem-bangbang", char: "‼" },
    { key: "fem-interrobang", char: "⁉" },
    { key: "fem-question", char: "❓" },
    { key: "fem-grey_question", char: "❔" },
    { key: "fem-grey_exclamation", char: "❕" },
    { key: "fem-exclamation", char: "❗" },
    { key: "fem-wavy_dash", char: "〰" },
    { key: "fem-copyright", char: "©" },
    { key: "fem-registered", char: "®" },
    { key: "fem-tm", char: "™" },
    { key: "fem-hash", char: "#⃣" },
    { key: "fem-keycap_star", char: "*⃣" },
    { key: "fem-zero", char: "0⃣" },
    { key: "fem-one", char: "1⃣" },
    { key: "fem-two", char: "2⃣" },
    { key: "fem-three", char: "3⃣" },
    { key: "fem-four", char: "4⃣" },
    { key: "fem-five", char: "5⃣" },
    { key: "fem-six", char: "6⃣" },
    { key: "fem-seven", char: "7⃣" },
    { key: "fem-eight", char: "8⃣" },
    { key: "fem-nine", char: "9⃣" },
    { key: "fem-keycap_ten", char: "🔟" },
    { key: "fem-100", char: "💯" },
    { key: "fem-capital_abcd", char: "🔠" },
    { key: "fem-abcd", char: "🔡" },
    { key: "fem-1234", char: "🔢" },
    { key: "fem-s", char: "🔣" },
    { key: "fem-abc", char: "🔤" },
    { key: "fem-a", char: "🅰" },
    { key: "fem-ab", char: "🆎" },
    { key: "fem-b", char: "🅱" },
    { key: "fem-cl", char: "🆑" },
    { key: "fem-cool", char: "🆒" },
    { key: "fem-free", char: "🆓" },
    { key: "fem-information_source", char: "ℹ" },
    { key: "fem-id", char: "🆔" },
    { key: "fem-m", char: "Ⓜ" },
    { key: "fem-new", char: "🆕" },
    { key: "fem-ng", char: "🆖" },
    { key: "fem-o2", char: "🅾" },
    { key: "fem-ok", char: "🆗" },
    { key: "fem-parking", char: "🅿" },
    { key: "fem-sos", char: "🆘" },
    { key: "fem-up", char: "🆙" },
    { key: "fem-vs", char: "🆚" },
    { key: "fem-koko", char: "🈁" },
    { key: "fem-sa", char: "🈂" },
    { key: "fem-u6708", char: "🈷" },
    { key: "fem-u6709", char: "🈶" },
    { key: "fem-u6307", char: "🈯" },
    {
      key: "fem-ideograph_advantage",
      char: "🉐"
    },
    { key: "fem-u5272", char: "🈹" },
    { key: "fem-u7121", char: "🈚" },
    { key: "fem-u7981", char: "🈲" },
    { key: "fem-accept", char: "🉑" },
    { key: "fem-u7533", char: "🈸" },
    { key: "fem-u5408", char: "🈴" },
    { key: "fem-u7a7a", char: "🈳" },
    { key: "fem-congratulations", char: "㊗" },
    { key: "fem-secret", char: "㊙" },
    { key: "fem-u55b6", char: "🈺" },
    { key: "fem-u6e80", char: "🈵" },
    { key: "fem-black_small_square", char: "▪" },
    { key: "fem-white_small_square", char: "▫" },
    { key: "fem-white_medium_square", char: "◻" },
    { key: "fem-black_medium_square", char: "◼" },
    {
      key: "fem-white_medium_small_square",
      char: "◽"
    },
    {
      key: "fem-black_medium_small_square",
      char: "◾"
    },
    { key: "fem-black_large_square", char: "⬛" },
    { key: "fem-white_large_square", char: "⬜" },
    {
      key: "fem-large_orange_diamond",
      char: "🔶"
    },
    { key: "fem-large_blue_diamond", char: "🔷" },
    {
      key: "fem-small_orange_diamond",
      char: "🔸"
    },
    { key: "fem-small_blue_diamond", char: "🔹" },
    { key: "fem-small_red_triangle", char: "🔺" },
    {
      key: "fem-small_red_triangle_down",
      char: "🔻"
    },
    {
      key: "fem-diamond_shape_with_a_dot_inside",
      char: "💠"
    },
    { key: "fem-radio_button", char: "🔘" },
    {
      key: "fem-black_square_button",
      char: "🔲"
    },
    {
      key: "fem-white_square_button",
      char: "🔳"
    },
    { key: "fem-white_circle", char: "⚪" },
    { key: "fem-black_circle", char: "⚫" },
    { key: "fem-red_circle", char: "🔴" },
    { key: "fem-large_blue_circle", char: "🔵" }
  ],
  activity: [
    { key: "fem-space_invader", char: "👾" },
    {
      key: "fem-man_in_business_suit_levitating",
      char: "🕴"
    },
    { key: "fem-horse_racing", char: "🏇" },
    { key: "fem-skier", char: "⛷" },
    { key: "fem-snowboarder", char: "🏂" },
    { key: "fem-golfer", char: "🏌" },
    { key: "fem-surfer", char: "🏄" },
    { key: "fem-rowboat", char: "🚣" },
    { key: "fem-swimmer", char: "🏊" },
    { key: "fem-person_with_ball", char: "⛹" },
    { key: "fem-weight_lifter", char: "🏋" },
    { key: "fem-bicyclist", char: "🚴" },
    { key: "fem-mountain_bicyclist", char: "🚵" },
    { key: "fem-circus_tent", char: "🎪" },
    { key: "fem-performing_arts", char: "🎭" },
    { key: "fem-art", char: "🎨" },
    { key: "fem-slot_machine", char: "🎰" },
    { key: "fem-bath", char: "🛀" },
    { key: "fem-reminder_ribbon", char: "🎗" },
    { key: "fem-admission_tickets", char: "🎟" },
    { key: "fem-ticket", char: "🎫" },
    { key: "fem-medal", char: "🎖" },
    { key: "fem-trophy", char: "🏆" },
    { key: "fem-sports_medal", char: "🏅" },
    { key: "fem-soccer", char: "⚽" },
    { key: "fem-baseball", char: "⚾" },
    { key: "fem-basketball", char: "🏀" },
    { key: "fem-volleyball", char: "🏐" },
    { key: "fem-football", char: "🏈" },
    { key: "fem-rugby_football", char: "🏉" },
    { key: "fem-tennis", char: "🎾" },
    { key: "fem-8ball", char: "🎱" },
    { key: "fem-bowling", char: "🎳" },
    {
      key: "fem-cricket_bat_and_ball",
      char: "🏏"
    },
    {
      key: "fem-field_hockey_stick_and_ball",
      char: "🏑"
    },
    {
      key: "fem-ice_hockey_stick_and_puck",
      char: "🏒"
    },
    {
      key: "fem-table_tennis_paddle_and_ball",
      char: "🏓"
    },
    {
      key: "fem-badminton_racquet_and_shuttlecock",
      char: "🏸"
    },
    { key: "fem-dart", char: "🎯" },
    { key: "fem-golf", char: "⛳" },
    { key: "fem-ice_skate", char: "⛸" },
    {
      key: "fem-fishing_pole_and_fish",
      char: "🎣"
    },
    {
      key: "fem-running_shirt_with_sash",
      char: "🎽"
    },
    { key: "fem-ski", char: "🎿" },
    { key: "fem-video_game", char: "🎮" },
    { key: "fem-game_die", char: "🎲" },
    { key: "fem-musical_score", char: "🎼" },
    { key: "fem-microphone", char: "🎤" },
    { key: "fem-headphones", char: "🎧" },
    { key: "fem-saxophone", char: "🎷" },
    { key: "fem-guitar", char: "🎸" },
    { key: "fem-musical_keyboard", char: "🎹" },
    { key: "fem-trumpet", char: "🎺" },
    { key: "fem-violin", char: "🎻" },
    { key: "fem-clapper", char: "🎬" },
    { key: "fem-bow_and_arrow", char: "🏹" }
  ],
  travel: [
    { key: "fem-racing_car", char: "🏎" },
    { key: "fem-racing_motorcycle", char: "🏍" },
    { key: "fem-japan", char: "🗾" },
    {
      key: "fem-snow_capped_mountain",
      char: "🏔"
    },
    { key: "fem-mountain", char: "⛰" },
    { key: "fem-volcano", char: "🌋" },
    { key: "fem-mount_fuji", char: "🗻" },
    { key: "fem-camping", char: "🏕" },
    { key: "fem-beach_with_umbrella", char: "🏖" },
    { key: "fem-desert", char: "🏜" },
    { key: "fem-desert_island", char: "🏝" },
    { key: "fem-national_park", char: "🏞" },
    { key: "fem-stadium", char: "🏟" },
    { key: "fem-classical_building", char: "🏛" },
    {
      key: "fem-building_construction",
      char: "🏗"
    },
    { key: "fem-house_buildings", char: "🏘" },
    { key: "fem-cityscape", char: "🏙" },
    {
      key: "fem-derelict_house_building",
      char: "🏚"
    },
    { key: "fem-house", char: "🏠" },
    { key: "fem-house_with_garden", char: "🏡" },
    { key: "fem-office", char: "🏢" },
    { key: "fem-post_office", char: "🏣" },
    {
      key: "fem-european_post_office",
      char: "🏤"
    },
    { key: "fem-hospital", char: "🏥" },
    { key: "fem-bank", char: "🏦" },
    { key: "fem-hotel", char: "🏨" },
    { key: "fem-love_hotel", char: "🏩" },
    { key: "fem-convenience_store", char: "🏪" },
    { key: "fem-school", char: "🏫" },
    { key: "fem-department_store", char: "🏬" },
    { key: "fem-factory", char: "🏭" },
    { key: "fem-japanese_castle", char: "🏯" },
    { key: "fem-european_castle", char: "🏰" },
    { key: "fem-wedding", char: "💒" },
    { key: "fem-tokyo_tower", char: "🗼" },
    { key: "fem-statue_of_liberty", char: "🗽" },
    { key: "fem-church", char: "⛪" },
    { key: "fem-mosque", char: "🕌" },
    { key: "fem-synagogue", char: "🕍" },
    { key: "fem-shinto_shrine", char: "⛩" },
    { key: "fem-kaaba", char: "🕋" },
    { key: "fem-fountain", char: "⛲" },
    { key: "fem-tent", char: "⛺" },
    { key: "fem-foggy", char: "🌁" },
    { key: "fem-night_with_stars", char: "🌃" },
    {
      key: "fem-sunrise_over_mountains",
      char: "🌄"
    },
    { key: "fem-sunrise", char: "🌅" },
    { key: "fem-city_sunset", char: "🌇" },
    { key: "fem-bridge_at_night", char: "🌉" },
    { key: "fem-milky_way", char: "🌌" },
    { key: "fem-carousel_horse", char: "🎠" },
    { key: "fem-ferris_wheel", char: "🎡" },
    { key: "fem-roller_coaster", char: "🎢" },
    { key: "fem-steam_locomotive", char: "🚂" },
    { key: "fem-railway_car", char: "🚃" },
    { key: "fem-bullettrain_side", char: "🚄" },
    { key: "fem-bullettrain_front", char: "🚅" },
    { key: "fem-train2", char: "🚆" },
    { key: "fem-metro", char: "🚇" },
    { key: "fem-light_rail", char: "🚈" },
    { key: "fem-station", char: "🚉" },
    { key: "fem-tram", char: "🚊" },
    { key: "fem-monorail", char: "🚝" },
    { key: "fem-mountain_railway", char: "🚞" },
    { key: "fem-train", char: "🚋" },
    { key: "fem-bus", char: "🚌" },
    { key: "fem-oncoming_bus", char: "🚍" },
    { key: "fem-trolleybus", char: "🚎" },
    { key: "fem-minibus", char: "🚐" },
    { key: "fem-ambulance", char: "🚑" },
    { key: "fem-fire_engine", char: "🚒" },
    { key: "fem-police_car", char: "🚓" },
    {
      key: "fem-oncoming_police_car",
      char: "🚔"
    },
    { key: "fem-taxi", char: "🚕" },
    { key: "fem-oncoming_taxi", char: "🚖" },
    { key: "fem-car", char: "🚗" },
    {
      key: "fem-oncoming_automobile",
      char: "🚘"
    },
    { key: "fem-blue_car", char: "🚙" },
    { key: "fem-truck", char: "🚚" },
    { key: "fem-articulated_lorry", char: "🚛" },
    { key: "fem-tractor", char: "🚜" },
    { key: "fem-bike", char: "🚲" },
    { key: "fem-busstop", char: "🚏" },
    { key: "fem-motorway", char: "🛣" },
    { key: "fem-railway_track", char: "🛤" },
    { key: "fem-fuelpump", char: "⛽" },
    { key: "fem-rotating_light", char: "🚨" },
    { key: "fem-traffic_light", char: "🚥" },
    {
      key: "fem-vertical_traffic_light",
      char: "🚦"
    },
    { key: "fem-construction", char: "🚧" },
    { key: "fem-anchor", char: "⚓" },
    { key: "fem-boat", char: "⛵" },
    { key: "fem-speedboat", char: "🚤" },
    { key: "fem-passenger_ship", char: "🛳" },
    { key: "fem-ferry", char: "⛴" },
    { key: "fem-motor_boat", char: "🛥" },
    { key: "fem-ship", char: "🚢" },
    { key: "fem-airplane", char: "✈" },
    { key: "fem-small_airplane", char: "🛩" },
    { key: "fem-airplane_departure", char: "🛫" },
    { key: "fem-airplane_arriving", char: "🛬" },
    { key: "fem-seat", char: "💺" },
    { key: "fem-helicopter", char: "🚁" },
    { key: "fem-suspension_railway", char: "🚟" },
    { key: "fem-mountain_cableway", char: "🚠" },
    { key: "fem-aerial_tramway", char: "🚡" },
    { key: "fem-rocket", char: "🚀" },
    { key: "fem-satellite", char: "🛰" },
    { key: "fem-stars", char: "🌠" },
    { key: "fem-rainbow", char: "🌈" },
    { key: "fem-fireworks", char: "🎆" },
    { key: "fem-sparkler", char: "🎇" },
    { key: "fem-rice_scene", char: "🎑" },
    { key: "fem-checkered_flag", char: "🏁" }
  ],
  objects: [
    {
      key: "fem-skull_and_crossbones",
      char: "☠"
    },
    { key: "fem-love_letter", char: "💌" },
    { key: "fem-bomb", char: "💣" },
    { key: "fem-hole", char: "🕳" },
    { key: "fem-shopping_bags", char: "🛍" },
    { key: "fem-prayer_beads", char: "📿" },
    { key: "fem-gem", char: "💎" },
    { key: "fem-amphora", char: "🏺" },
    { key: "fem-world_map", char: "🗺" },
    { key: "fem-barber", char: "💈" },
    { key: "fem-frame_with_picture", char: "🖼" },
    { key: "fem-bellhop_bell", char: "🛎" },
    { key: "fem-door", char: "🚪" },
    {
      key: "fem-sleeping_accommodation",
      char: "🛌"
    },
    { key: "fem-bed", char: "🛏" },
    { key: "fem-couch_and_lamp", char: "🛋" },
    { key: "fem-toilet", char: "🚽" },
    { key: "fem-shower", char: "🚿" },
    { key: "fem-bathtub", char: "🛁" },
    { key: "fem-hourglass", char: "⌛" },
    {
      key: "fem-hourglass_flowing_sand",
      char: "⏳"
    },
    { key: "fem-watch", char: "⌚" },
    { key: "fem-alarm_clock", char: "⏰" },
    { key: "fem-stopwatch", char: "⏱" },
    { key: "fem-timer_clock", char: "⏲" },
    { key: "fem-mantelpiece_clock", char: "🕰" },
    { key: "fem-thermometer", char: "🌡" },
    { key: "fem-beach_umbrella", char: "⛱" },
    { key: "fem-balloon", char: "🎈" },
    { key: "fem-tada", char: "🎉" },
    { key: "fem-confetti_ball", char: "🎊" },
    { key: "fem-dolls", char: "🎎" },
    { key: "fem-f", char: "🎏" },
    { key: "fem-wind_chime", char: "🎐" },
    { key: "fem-ribbon", char: "🎀" },
    { key: "fem-gift", char: "🎁" },
    { key: "fem-joystick", char: "🕹" },
    { key: "fem-postal_horn", char: "📯" },
    { key: "fem-studio_microphone", char: "🎙" },
    { key: "fem-level_slider", char: "🎚" },
    { key: "fem-control_knobs", char: "🎛" },
    { key: "fem-radio", char: "📻" },
    { key: "fem-iphone", char: "📱" },
    { key: "fem-calling", char: "📲" },
    { key: "fem-telephone", char: "☎" },
    { key: "fem-telephone_receiver", char: "📞" },
    { key: "fem-pager", char: "📟" },
    { key: "fem-fax", char: "📠" },
    { key: "fem-battery", char: "🔋" },
    { key: "fem-electric_plug", char: "🔌" },
    { key: "fem-computer", char: "💻" },
    { key: "fem-desktop_computer", char: "🖥" },
    { key: "fem-printer", char: "🖨" },
    { key: "fem-keyboard", char: "⌨" },
    { key: "fem-three_button_mouse", char: "🖱" },
    { key: "fem-trackball", char: "🖲" },
    { key: "fem-minidisc", char: "💽" },
    { key: "fem-floppy_disk", char: "💾" },
    { key: "fem-cd", char: "💿" },
    { key: "fem-dvd", char: "📀" },
    { key: "fem-movie_camera", char: "🎥" },
    { key: "fem-film_frames", char: "🎞" },
    { key: "fem-film_projector", char: "📽" },
    { key: "fem-tv", char: "📺" },
    { key: "fem-camera", char: "📷" },
    { key: "fem-camera_with_flash", char: "📸" },
    { key: "fem-video_camera", char: "📹" },
    { key: "fem-vhs", char: "📼" },
    { key: "fem-mag", char: "🔍" },
    { key: "fem-mag_right", char: "🔎" },
    { key: "fem-microscope", char: "🔬" },
    { key: "fem-telescope", char: "🔭" },
    { key: "fem-satellite_antenna", char: "📡" },
    { key: "fem-candle", char: "🕯" },
    { key: "fem-bulb", char: "💡" },
    { key: "fem-flashlight", char: "🔦" },
    { key: "fem-izakaya_lantern", char: "🏮" },
    {
      key: "fem-notebook_with_decorative_cover",
      char: "📔"
    },
    { key: "fem-closed_book", char: "📕" },
    { key: "fem-book", char: "📖" },
    { key: "fem-green_book", char: "📗" },
    { key: "fem-blue_book", char: "📘" },
    { key: "fem-orange_book", char: "📙" },
    { key: "fem-books", char: "📚" },
    { key: "fem-notebook", char: "📓" },
    { key: "fem-ledger", char: "📒" },
    { key: "fem-page_with_curl", char: "📃" },
    { key: "fem-scroll", char: "📜" },
    { key: "fem-page_facing_up", char: "📄" },
    { key: "fem-newspaper", char: "📰" },
    { key: "fem-rolled_up_newspaper", char: "🗞" },
    { key: "fem-bookmark_tabs", char: "📑" },
    { key: "fem-bookmark", char: "🔖" },
    { key: "fem-label", char: "🏷" },
    { key: "fem-moneybag", char: "💰" },
    { key: "fem-yen", char: "💴" },
    { key: "fem-dollar", char: "💵" },
    { key: "fem-euro", char: "💶" },
    { key: "fem-pound", char: "💷" },
    { key: "fem-money_with_wings", char: "💸" },
    { key: "fem-credit_card", char: "💳" },
    { key: "bem bem-e-mail ap ap-e-mail", char: "📧" },
    { key: "fem-incoming_envelope", char: "📨" },
    {
      key: "fem-envelope_with_arrow",
      char: "📩"
    },
    { key: "fem-outbox_tray", char: "📤" },
    { key: "fem-inbox_tray", char: "📥" },
    { key: "fem-package", char: "📦" },
    { key: "fem-mailbox", char: "📫" },
    { key: "fem-mailbox_closed", char: "📪" },
    { key: "fem-mailbox_with_mail", char: "📬" },
    {
      key: "fem-mailbox_with_no_mail",
      char: "📭"
    },
    { key: "fem-postbox", char: "📮" },
    {
      key: "fem-ballot_box_with_ballot",
      char: "🗳"
    },
    { key: "fem-pencil2", char: "✏" },
    { key: "fem-black_nib", char: "✒" },
    {
      key: "fem-lower_left_fountain_pen",
      char: "🖋"
    },
    {
      key: "fem-lower_left_ballpoint_pen",
      char: "🖊"
    },
    {
      key: "fem-lower_left_paintbrush",
      char: "🖌"
    },
    { key: "fem-lower_left_crayon", char: "🖍" },
    { key: "fem-memo", char: "📝" },
    { key: "fem-file_folder", char: "📁" },
    { key: "fem-open_file_folder", char: "📂" },
    { key: "fem-card_index_dividers", char: "🗂" },
    { key: "fem-date", char: "📅" },
    { key: "fem-calendar", char: "📆" },
    { key: "fem-spiral_note_pad", char: "🗒" },
    { key: "fem-spiral_calendar_pad", char: "🗓" },
    { key: "fem-card_index", char: "📇" },
    {
      key: "fem-chart_with_upwards_trend",
      char: "📈"
    },
    {
      key: "fem-chart_with_downwards_trend",
      char: "📉"
    },
    { key: "fem-bar_chart", char: "📊" },
    { key: "fem-clipboard", char: "📋" },
    { key: "fem-pushpin", char: "📌" },
    { key: "fem-round_pushpin", char: "📍" },
    { key: "fem-paperclip", char: "📎" },
    { key: "fem-linked_paperclips", char: "🖇" },
    { key: "fem-straight_ruler", char: "📏" },
    { key: "fem-triangular_ruler", char: "📐" },
    { key: "fem-scissors", char: "✂" },
    { key: "fem-card_file_box", char: "🗃" },
    { key: "fem-file_cabinet", char: "🗄" },
    { key: "fem-wastebasket", char: "🗑" },
    { key: "fem-lock", char: "🔒" },
    { key: "fem-unlock", char: "🔓" },
    { key: "fem-lock_with_ink_pen", char: "🔏" },
    {
      key: "fem-closed_lock_with_key",
      char: "🔐"
    },
    { key: "fem-key", char: "🔑" },
    { key: "fem-old_key", char: "🗝" },
    { key: "fem-hammer", char: "🔨" },
    { key: "fem-pick", char: "⛏" },
    { key: "fem-hammer_and_pick", char: "⚒" },
    { key: "fem-hammer_and_wrench", char: "🛠" },
    { key: "fem-dagger_knife", char: "🗡" },
    { key: "fem-crossed_swords", char: "⚔" },
    { key: "fem-gun", char: "🔫" },
    { key: "fem-shield", char: "🛡" },
    { key: "fem-wrench", char: "🔧" },
    { key: "fem-nut_and_bolt", char: "🔩" },
    { key: "fem-gear", char: "⚙" },
    { key: "fem-compression", char: "🗜" },
    { key: "fem-alembic", char: "⚗" },
    { key: "fem-scales", char: "⚖" },
    { key: "fem-link", char: "🔗" },
    { key: "fem-chains", char: "⛓" },
    { key: "fem-syringe", char: "💉" },
    { key: "fem-pill", char: "💊" },
    { key: "fem-smoking", char: "🚬" },
    { key: "fem-coffin", char: "⚰" },
    { key: "fem-funeral_urn", char: "⚱" },
    { key: "fem-moyai", char: "🗿" },
    { key: "fem-oil_drum", char: "🛢" },
    { key: "fem-crystal_ball", char: "🔮" },
    {
      key: "fem-triangular_flag_on_post",
      char: "🚩"
    },
    { key: "fem-crossed_flags", char: "🎌" },
    { key: "fem-waving_black_flag", char: "🏴" },
    { key: "fem-waving_white_flag", char: "🏳" }
  ]
};
