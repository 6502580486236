<template>
  <el-popover popper-class="f-emoji-popover" v-model="visible">
    <div class="f-emoji-box">
      <ul class="tabs">
        <li
          v-for="tab of tabs"
          :key="tab"
          :class="[tab, activeTabKey === tab ? 'active' : '']"
          @click="activeTabKey = tab"
        ></li>
      </ul>
      <ul class="tab-panel">
        <li
          v-for="em in emojiList"
          :class="em.key"
          :key="em.key"
          @click="handleEmClick(em)"
        >
          {{ em.char }}
        </li>
      </ul>
    </div>
    <div
      slot="reference"
      class="reference"
      :class="{ trigger: !$slots.default }"
    >
      <slot></slot>
    </div>
  </el-popover>
</template>

<script>
import emojiData from "./data";
const tabs = Object.keys(emojiData);
export default {
  data() {
    return {
      emojiData,
      tabs,
      activeTabKey: tabs[0],
      visible: false
    };
  },
  computed: {
    emojiList() {
      return emojiData[this.activeTabKey];
    }
  },
  methods: {
    handleEmClick(em) {
      this.$emit("select", em.char, em);
      this.visible = false;
    }
  }
};
</script>
<style lang="scss">
.f-emoji-popover {
  padding: 0;
}
</style>
<style scoped lang="scss">
.reference {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
.trigger {
  width: 24px;
  height: 24px;
  background: url("./icons/people.svg") no-repeat center;
  background-size: 24px;
}
.f-emoji-box {
  width: 360px;
  .tabs {
    display: flex;
    background: #f8f8f8;
    border-bottom: 1px solid #eee;
    li {
      padding: 10px 0 7px 0;
      flex: 1;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 30px 30px;
      cursor: pointer;
      border-bottom: 3px solid transparent;
      &.active {
        border-bottom-color: #2ab27b;
      }
    }
    .people {
      background-image: url("./icons/people.svg");
    }
    .nature {
      background-image: url("./icons/nature.svg");
    }
    .food {
      background-image: url("./icons/food.svg");
    }
    .symbols {
      background-image: url("./icons/symbols.svg");
    }
    .activity {
      background-image: url("./icons/activity.svg");
    }
    .travel {
      background-image: url("./icons/travel.svg");
    }
    .objects {
      background-image: url("./icons/objects.svg");
    }
  }
  .tab-panel {
    overflow-x: hidden;
    overflow-y: auto;
    height: 350px;
    li {
      width: 35px;
      height: 35px;
      float: left;
      font-size: 28px;
      cursor: pointer;
      @include flex-xyc;
      &:hover {
        background: #f8f8f8;
      }
    }
  }
}
</style>
