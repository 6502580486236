var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-popover', {
    attrs: {
      "popper-class": "f-emoji-popover"
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('div', {
    staticClass: "f-emoji-box"
  }, [_c('ul', {
    staticClass: "tabs"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('li', {
      key: tab,
      class: [tab, _vm.activeTabKey === tab ? 'active' : ''],
      on: {
        "click": function click($event) {
          _vm.activeTabKey = tab;
        }
      }
    });
  }), 0), _c('ul', {
    staticClass: "tab-panel"
  }, _vm._l(_vm.emojiList, function (em) {
    return _c('li', {
      key: em.key,
      class: em.key,
      on: {
        "click": function click($event) {
          return _vm.handleEmClick(em);
        }
      }
    }, [_vm._v(" " + _vm._s(em.char) + " ")]);
  }), 0)]), _c('div', {
    staticClass: "reference",
    class: {
      trigger: !_vm.$slots.default
    },
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }