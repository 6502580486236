<template>
  <div class="container">
    <div class="emoji-edit">
      <emoji-box @select="selectEmoji"></emoji-box>
    </div>
    <div class="main-row">
      <div class="view-card">
        <el-image fit="cover" :src="$platformInfo.logo" class="plat-logo">
          <i slot="error" class="el-icon-picture"></i>
        </el-image>
        <div class="message-box">
          {{ formData.context || "请输入文字消息" }}
          <span class="t-right-n"></span>
        </div>
      </div>
      <div class="editor-container">
        <div class="edit-main">
          <el-input
            id="textInput"
            v-model="formData.context"
            type="textarea"
            resize="none"
            size="mini"
            placeholder="在这里开始输入文字消息"
          ></el-input>
          <!--发表设置-->
          <public-setting
            :detailForm="detailForm"
            ref="setting"
            class="edit-set"
          ></public-setting>
          <c-foot-bar
            :word-count="formData.context?.length"
            :form-data="{ ...formData, ...$refs.setting?.formData }"
            :before-submit="validateForm"
          ></c-foot-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emojiBox from "@/components/emoji-box/index";
import publicSetting from "./_components/publicSetting";
import CFootBar from "./_components/footBar";
import { getMessageDetail } from "@/api/message";
export default {
  components: { emojiBox, publicSetting, CFootBar },
  data() {
    return {
      formData: {
        context: "",
        publishType: 3 //文本消息
      },
      detailForm: {}
    };
  },
  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    //获取详情
    async getDetail() {
      const res = await getMessageDetail(this.$route.query.id);
      if (res) {
        this.formData.context = res.context;
        this.formData.id = res.id;
        this.detailForm = res;
      }
    },
    //选择表情
    selectEmoji(e) {
      let textArea = document.getElementById("textInput");
      function changeSelectedText(obj, str) {
        if (window.getSelection) {
          // 非IE浏览器
          textArea.setRangeText(str);
          // 在未选中文本的情况下，重新设置光标位置
          textArea.selectionStart += str.length;
          textArea.focus();
        } else if (document.selection) {
          // IE浏览器
          obj.focus();
          let sel = document.selection.createRange();
          sel.text = str;
        }
      }
      changeSelectedText(textArea, e);
      this.formData.context = textArea.value; // 同步formData中的数据
    },
    // 验证表单是否填写完整
    validateForm() {
      const { context } = this.formData;
      const { formData, regularMass, dayError } = this.$refs.setting;
      let msg;
      if (!context.trim()) {
        msg = "请输入内容";
      } else if (formData.dataType == 1 && regularMass && dayError) {
        msg = "无剩余次数";
      }
      if (msg) {
        this.$showError(msg);
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  margin: -20px;
  height: calc(100% + 40px);
  background: #f8f8f8;
  overflow: hidden;
  .emoji-edit {
    height: 53px;
    background: #f5f5f5;
    @include flex-xyc;
  }
  .main-row {
    display: flex;
    align-items: flex-start;
    position: relative;
    .view-card {
      display: flex;
      padding: 30px 0 30px 40px;
      .plat-logo {
        width: 45px;
        height: 45px;
        border-radius: 5px;
        font-size: 24px;
        background: #eee;
        @include flex-xyc;
      }
      .message-box {
        width: 266px;
        min-height: 45px;
        border-radius: 5px;
        background: #fff;
        position: relative;
        margin-left: 20px;
        padding: 10px 14px;
        font-size: 15px;
        line-height: 24px;
        word-break: break-all;
        white-space: pre-line;
        max-height: calc(100vh - 173px);
        overflow-y: auto;
        .t-right-n {
          display: block;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-right: 8px solid #fff;
          border-bottom: 5px solid transparent;
          position: absolute;
          left: -8px;
          top: 17px;
          z-index: 0;
        }
      }
    }
    .editor-container {
      flex: 1;
      display: flex;
      justify-content: center;
      overflow-y: auto;
      padding: 30px 40px 30px 0;
      height: calc(100vh - 113px);
      .edit-main {
        width: 800px;
        height: fit-content;
        background: #fff;
        border-radius: 8px;
        ::v-deep .el-textarea__inner {
          height: 400px;
          border: none;
          font-size: 15px;
          color: #333;
          padding: 50px 80px 20px;
        }
        .edit-set {
          margin: 50px 80px 0;
        }
      }
    }
  }
}
</style>
